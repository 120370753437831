<template>
  <mytooltip :offset="5">
    <template #default>
      <div class="lowest-price-container text-color-lightblue leading-tight">
        <template v-for="(item, index) in showList" :key="index">
          <div v-if="item?.price > 0" class="flex items-center text-14">
            <a
              :href="item.link"
              target="blank"
              class="flex items-center justify-between"
              :class="item.lowest ? 'text-color-orange' : 'text-color-lightblue'"
            >
              <span class="mr-5"
                ><i class="iconfont icon-guanfangmeiti text-26" :class="`icon-${item.platform}`" :style="{ fontSize: props.size }"
              /></span>
              <div class="process-bar-container mr-5">
                <div
                  :class="['process-bar', item.lowest ? 'lowest' : '']"
                  :style="{
                    width: `${(item.price / data.hightest) * 100}%`,
                    minWidth: '4px'
                  }"
                />
              </div>
              <div class="text-14">
                {{ item.price > 0 ? `${monetaryUnit()}${currencyTransform(item.price)}` : `${monetaryUnit()} -` }}
              </div>
            </a>
          </div>
        </template>
      </div>
    </template>

    <template #content>
      <div class="lowest-price-list text-color-lightblue pb-15 pt-14 text-14">
        <div v-for="(item, index) in list" :key="index" class="price-list-item">
          <a
            :href="item.link"
            target="blank"
            class="flex items-center justify-between"
            :class="item.lowest ? 'text-color-orange' : 'text-color-lightblue'"
          >
            <div class="flex items-center">
              <i class="iconfont icon-guanfangmeiti mr-5 text-16" :class="`icon-${item.platform}`"></i>
              <span>{{ item.platformName }}</span>
            </div>
            <div class="item-price text-14">
              {{ item.price > 0 ? `${monetaryUnit()}${currencyTransform(item.price)}` : '¥ -' }}
            </div>
          </a>
        </div>

        <p class="text-color-lightblue mt-6 px-20 text-14">
          {{ $t('数据更新于')
          }}{{
            dayjs(updateTime * 1000)
              .fromNow()
              .replace(' ', '')
          }}
        </p>
      </div>
    </template>
  </mytooltip>
</template>

<script setup>
import mytooltip from '~/components/common/mytooltip.vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.locale('zh-cn')

const props = defineProps({
  size: {
    type: String,
    default: '26px'
  },
  list: {
    type: Array,
    default: []
  }
})
const showList = ref([])
const tooltipList = ref([])

const updateTime = ref(0)
const platform = useCookie('SDT_PlatformList')
const list = computed(() => {
  const sortList = props.list.sort((a, b) => Number(a.price) - Number(b.price))

  let newList = []
  sortList.forEach((item, index) => {
    if (item.platform === 'steam') {
      newList.unshift(item)
    } else {
      newList.push(item)
    }

    // 更新时间
    updateTime.value = item.updateTime > updateTime.value ? item.updateTime : updateTime.value
  })

  return newList
})

watch(platform, val => {
  readShowList(val)
})

const data = ref({
  hightest: 0
})
const tempArr = []
for (const item of list.value) {
  if (item.price) {
    tempArr.push(item.price)
  }
}
var max = Math.max.apply(null, tempArr)
var min = Math.min.apply(null, tempArr)
data.value.hightest = max
for (const item of list.value) {
  if (item.price == min) {
    item.lowest = true
  }
}

readShowList(platform.value)

// 读取显示列表
function readShowList(platform) {
  showList.value = []
  for (const item of list.value) {
    platform.forEach(data => {
      if (item.platform === data) {
        showList.value.push(item)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');
.lowest-price-container {
  .process-bar-container {
    width: 50px;
    .process-bar {
      width: 50%;
      height: 5px;
      border-radius: 2.5px;
      background-color: $light-blue-color;
      &.lowest {
        background-color: $orange-color;
      }
    }
  }
}

.lowest-price-list {
  width: 220px;
  margin: 0 -20px;

  .price-list-item {
    cursor: pointer;
    padding: 6px 20px;

    &:hover {
      color: $black-color;
      background-color: $border-gray-color;

      .item-price {
        color: $black-color;
      }
    }
  }
}
</style>
